//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';

import useMobile from '../../hooks/useMobile';
import styles from './styles';
import Button from '../Button';
import Wrapper from '../../components/wrapper/wrapper';

import { theme1Colors, theme2Colors, theme3Colors, TTheme } from '../../configs/theme';
import { EButtonVariant } from '../../models/button';
import { TRequest } from '../../models/request';
//#endregion

type TProps = {
  readonly request: TRequest;
  readonly designId: string;
  handlerSubscribe(): void;
};

const Prizes: FC<TProps> = ({ handlerSubscribe, request }) => {
  const theme = useTheme<TTheme>();
  const { isMobile } = useMobile();
  const { t, i18n } = useTranslation();
  const classes = styles({ isMobile });

  const getColorForItemPrize = () => {
    switch (theme.name) {
      case 'theme2':
        return theme2Colors.green;
      case 'theme3':
        return theme3Colors.darkPurple;
      default:
        return theme1Colors.blue;
    }
  };
  const getColorForItemSVG = () => {
    switch (theme.name) {
      case 'theme2':
        return theme2Colors.red;
      case 'theme3':
        return theme3Colors.yellow;
      default:
        return theme1Colors.yellow;
    }
  };

  const prizesList = [...Array(9)].map((el, i) => {
    return (
      <div className={ `${classes.item} ${theme.name} ${i18n.language}` } key={ i }>
        <div className={ `${classes.icon} ${theme.name}` }>
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke={ getColorForItemSVG() } d="M0 20.8333H25M0 24.1666H25M4.16667 6.66659V4.16659H6.66667M18.3333 4.16659H20.8333V6.66659M4.16667 11.6666V14.1666H6.66667M18.3333 14.1666H20.8333V11.6666M12.5 12.4999C10.6591 12.4999 9.16667 11.0075 9.16667 9.16659C9.16667 7.32564 10.6591 5.83325 12.5 5.83325C14.3409 5.83325 15.8333 7.32564 15.8333 9.16659C15.8333 11.0075 14.3409 12.4999 12.5 12.4999ZM2.5 0.833252H22.5C23.4205 0.833252 24.1667 1.57944 24.1667 2.49992V15.8333C24.1667 16.7537 23.4205 17.4999 22.5 17.4999H2.5C1.57953 17.4999 0.833333 16.7537 0.833333 15.8333V2.49992C0.833333 1.57944 1.57953 0.833252 2.5 0.833252Z"/>
          </svg>
        </div>
        <span className={ 'text-wrapper' } dangerouslySetInnerHTML={ { __html: t(`prizes.prize${i}`, { color: getColorForItemPrize() }) } }/>
      </div>
    );
  });

  const handlerSubscribeButton = () => {
    handlerSubscribe();
    // @ts-ignore
    // window.ym(93217884, 'reachGoal', `design${designId}_prizes_subscription_click`);
  };

  const buttons =
    <div className={ `${classes.buttons} ${theme.name} ${i18n.language}` }>
      <Button
        readonly={ request.status === 'ok' }
        disabled={ request.status === 'loading' }
        variant={ `${theme.name === 'theme3' ? 'primary' : 'primaryContrast'}` as EButtonVariant }
        title={ t(`action.subscribe.${ theme.name }`) }
        isMobile={ isMobile }
        className={ theme.name === 'theme4' ? `btn-animated` : '' }
        onClick={ handlerSubscribeButton }
      />
    </div>
  ;

  const figure =
    <div className={ `${classes.superPrizeContainer} ${theme.name}` }>
      <div className={ `${classes.icon} ${theme.name}` }>
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            stroke={ theme.name === 'theme4' ? theme.palette.secondary.contrastText : theme.palette.secondary.main }
            d="M10.1335 36.7333H27.8668M19.0002 36.7333V24.0666M19.0002 24.0666C24.5967 24.0666 29.1335 19.5298 29.1335 13.9333V3.79993C29.1335 2.40081 27.9993 1.2666 26.6002 1.2666H11.4002C10.0011 1.2666 8.86684 2.40081 8.86684 3.79993V13.9333C8.86684 19.5298 13.4037 24.0666 19.0002 24.0666ZM8.86684 6.33327H6.33351C3.53527 6.33327 1.26685 8.60169 1.26685 11.3999C1.26685 14.1982 3.53527 16.4666 6.33351 16.4666H8.86684M29.1335 6.33327H31.6668C34.4651 6.33327 36.7335 8.60169 36.7335 11.3999C36.7335 14.1982 34.4651 16.4666 31.6668 16.4666H29.1335"
          />
        </svg>
      </div>
      <div className={ `${classes.superPrize} ${theme.name}` }>
        <p className={ classes.text }>{ t('prizes.superPrizeLabel') }</p>
        <p className={ `${classes.amount} ${theme.name}` }>{ t('prizes.superPrize') }</p>
      </div>
    </div>
  ;

  return (
    <>
      { theme.name === 'theme2' ? <div className={ `${classes.picture} ${theme.name}` } /> : null }
      <div className={ `${classes.root} ${theme.name}` }>
        { theme.name === 'theme1' ? <div className={ `${classes.picture} ${theme.name}` }/> : null }
        <Wrapper className={ `${classes.wrapper} ${theme.name}` }>
          <h3 dangerouslySetInnerHTML={ { __html: t('prizes.header') } } className={ `${classes.header} ${theme.name}` } id="prizes"/>
          <section className={ `${classes.container} ${theme.name}` }>
            <div>
              <p className={ classes.label }>{ t('prizes.label') }</p>
              { figure }
              { prizesList }
              { buttons }
            </div>
          </section>
        </Wrapper>
      </div>
    </>
  );
};

export default Prizes;
