//#region imports
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { theme1Fonts, TTheme } from '../../configs/theme';
//#endregion

interface IProps {
  isInverse?: boolean;
}

export default makeStyles<TTheme, IProps>((theme: TTheme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    minHeight: 82,
    backgroundColor: ({ isInverse }) => isInverse ? theme.palette.common.white : theme.palette.background.default
  },
  container: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100vw',
    maxWidth: '100%',
    padding: 20
  },
  drawer: {
    width: '100vw',
    height: '100vh',
    maxWidth: '100%'
  },
  menuButton: {
    background: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    borderRadius: '50px',
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.25)',
    fontSize: 14,
    fontFamily: theme.typography.caption.fontFamily,
    cursor: 'pointer'
  },
  locale: {
    width: 30,
    height: 30,
    fontFamily: theme.typography.caption.fontFamily,
    textTransform: 'uppercase'
  },
  menu: {
    width: 63,
    height: 30,
    cursor: 'pointer'
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    height: 'unset',
    width: 80,
    position: 'relative',
    left: -6,
    top: 4
  },
  logoText: {
    '&.theme1': {
      color: theme.palette.common.white,
      fontSize: 12,
      marginTop: -6,
      marginLeft: 3,
      fontFamily: theme1Fonts.inter,
      fontWeight: 500
    },
    '&.header.theme1': {
      color: theme.palette.primary.main
    }
  },
  close: {
    width: 28,
    height: 28,
    cursor: 'pointer'
  },
  header: {
    height: 91,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 25px 0 10px'
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 25px'
  },
  link: {
    padding: '16px 0',
    color: theme.palette.common.white,
    textDecoration: 'none',
    fontSize: 16,
    fontFamily: theme.typography.body1.fontFamily,
    borderBottom: `1px solid ${ theme.palette.common.white }`
  },
  buttons: {
    width: 128,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '40px auto 0 auto'
  },
  button: {
    width: 44,
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    border: `2px solid ${ theme.palette.common.white }`,
    borderRadius: '50%',
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontSize: 16
  },
  activeButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white
  },
  info: {
    width: '80%',
    margin: '40px auto 0 auto',
    color: theme.palette.common.white,
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: 16,
    lineHeight: '20px',
    textAlign: 'center',
    '& a': {
      color: theme.palette.common.white,
      fontFamily: theme.typography.caption.fontFamily
    }
  }
}),
  { name: 'MenuMobile' }
);
