//#region imports
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TTheme } from '../../configs/theme';
import bgSubscribeModalTheme1 from '../../assets/img/bg-subscribe-modal-theme1.webp';
import bgSubscribeModalTheme2 from '../../assets/img/bg-subscribe-modal-theme2.webp';
import bgSubscribeModalTheme3 from '../../assets/img/bg-subscribe-modal-theme3.webp';
//#endregion

export default makeStyles<TTheme>((theme: TTheme) => createStyles({
  content: {
    '&.ok': {
      '&.theme1': {
        background: `center 80% / contain no-repeat url(${bgSubscribeModalTheme1})`
      },
      '&.theme2': {
        background: `bottom / contain no-repeat url(${bgSubscribeModalTheme2})`
      },
      '&.theme3': {
        background: `bottom / contain no-repeat url(${bgSubscribeModalTheme3})`
      }
    }
  },
  wrapperMobile: {
    '& .MuiDrawer-paper': {
      width: 'calc(100vw - 60px)',
      padding: 30,
      backgroundColor: theme.palette.common.white
    }
  },
  wrapperDesktop: {
    '& .MuiPaper-rounded': {
      borderRadius: 15
    },
    '& .MuiDialog-paper': {
      maxWidth: 560,
      padding: '40px 40px 50px 40px'
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    paddingBottom: 30,
    fontSize: 24,
    fontFamily: theme.typography.caption.fontFamily,
    color: theme.palette.primary.main
  },
  close: {
    width: 20,
    height: 20,
    cursor: 'pointer'
  },
  desc: {
    fontSize: 15,
    paddingRight: 0,
    lineHeight: '18.31px',
    fontFamily: theme.typography.fontFamily,
    '& a': {
      fontFamily: theme.typography.caption.fontFamily,
      color: theme.palette.primary.main,
      textDecoration: 'underline'
    },
    '& b': {
      fontFamily: theme.typography.caption.fontFamily
    }
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
    '.err &': {
      marginTop: 10
    }
  }
}),
  { name: 'Modal' }
);
