//#region imports
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { ru, kz } from '../../locales/index';
//#endregion

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'ru',
    resources: {
      ru: {
        translations: { ...ru }
      },
      kz: {
        translations: { ...kz }
      }
    },
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
