import { createTheme } from '@material-ui/core/styles';
import { TTheme } from './index';

export const theme4Colors = {
  white: '#FFFFFF',
  grey: '#C4C4C4',
  green: '#006F00',
  yellow: '#FECA0A',
  blue: '#275EAC',
  dark: '#3A3A3A',
  neutral: '#8F8F8F'
};

export const theme4Fonts = {
  calibri: 'Calibri, Roboto, Arial, sans-serif',
  calibriBold: 'CalibriBold, Roboto, Arial, sans-serif'
};

export const theme4 = createTheme({
  name: 'theme4',
  palette: {
    background: {
      paper: theme4Colors.blue,
      default: theme4Colors.yellow
    },
    text: {
      secondary: theme4Colors.dark,
      disabled: theme4Colors.neutral,
      hint: theme4Colors.neutral
    },
    common: {
      white: theme4Colors.white
    },
    grey: {
      A100: theme4Colors.grey
    },
    primary: {
      main: theme4Colors.blue,
      contrastText: theme4Colors.blue,
      light: theme4Colors.neutral
    },
    secondary: {
      main: theme4Colors.blue,
      contrastText: theme4Colors.yellow
    },
    success: {
      main: theme4Colors.green
    },
    info: {
      main: theme4Colors.yellow,
      contrastText: theme4Colors.white
    },
    action: {
      selected: theme4Colors.yellow
    }
  },
  typography: {
    fontFamily: theme4Fonts.calibri,
    body1: {
      fontFamily: theme4Fonts.calibri
    },
    caption: {
      fontFamily: theme4Fonts.calibriBold
    },
    button: {
      fontFamily: theme4Fonts.calibriBold
    }
  },
  overrides: {
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: 'none'
        }
      },
      text: {
        padding: '0'
      }
    }
  }
} as unknown as TTheme);
