import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import closeDark from '../../assets/svg/close.dark.svg';
import JsonFormatter from 'react-json-formatter';
import Index from '../Button';
import stylesModal from '../Modal/styles';
import { useHeadersContext } from '../../app';
import { useLocation } from 'react-router';
import { useTheme } from '@material-ui/core';
import { TTheme } from '../../configs/theme';
import { useTranslation } from 'react-i18next';

const CheckHeaders = ({
  clickCount,
  setClickCount,
  clickId,
  partId,
  textId
}: {
  clickCount: number;
  setClickCount(number: number): void;
  clickId: string;
  partId: string;
  textId: string;
  hidden: boolean;
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme<TTheme>();
  const { headers } = useHeadersContext();
  const classes = stylesModal({ isMobile: true });
  const searchQuery = new URLSearchParams(location.search);
  const jsonStyle = {
    propertyStyle: { color: 'red' },
    stringStyle: { color: 'green' },
    numberStyle: { color: 'darkorange' },
    style: { fontSize: 14 }
  };

  return (
    <Drawer
      className={ classes.wrapperMobile }
      open={ clickCount === 10 }
      onClose={ () => setClickCount(0) }
    >
      <Box height="100vh" display="flex" flexDirection="column" className={ `${classes.content} ${theme.name}` }>
        <div className={ classes.header }>
          <h3 className={ classes.title }>QA Панель</h3>
          <img alt={ '' } className={ classes.close } src={ closeDark } onClick={ () => setClickCount(0) }/>
        </div>
        <small>
          Заголовки ответа:
        </small>
        { <JsonFormatter json={ JSON.stringify(headers) } tabWith={ 2 } jsonStyle={ jsonStyle } /> }
        <br/>
        <small>
          Get-параметры:
        </small>
        {
          <JsonFormatter
            json={
              JSON.stringify({
                click_id: clickId,
                part_id: partId,
                text_id: textId,
                design: searchQuery?.get('design'),
                hidden: searchQuery?.get('hidden'),
                lang: searchQuery?.get('lang')
              })
            }
            tabWith={ 2 }
            jsonStyle={ jsonStyle }
          />
        }
        <br/>
        <small>
          Payload запроса на сервер:
        </small>
        {
          <JsonFormatter
            json={
              JSON.stringify({
                msg: {

                  command: 'on',
                  click_id: clickId,
                  part_id: partId
                }
              })
            }
            tabWith={ 2 }
            jsonStyle={ jsonStyle }
          />
        }
        <div className={ classes.button }>
          <Index
            title={ t('modal.subscribe.button') }
            isMobile
            onClick={ () => setClickCount(0) }
          />
        </div>
      </Box>
    </Drawer>
  );
};

export default CheckHeaders;
