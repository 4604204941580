import { createTheme } from '@material-ui/core/styles';
import { TTheme } from './index';

export const theme3Fonts = {
  inter: 'Inter, Roboto, Arial, sans-serif',
  interBold: 'InterBold, Roboto, Arial, sans-serif'
};

export const theme3Colors = {
  white: '#FFFFFF',
  grey: '#E4E4E6',
  yellow: '#FECA0A',
  purple1: '#F21F52',
  purple2: '#4701B9',
  darkPurple: '#6A006D',
  dark: '#3A3A3A',
  neutral: '#8F8F8F'
};

export const theme3 = createTheme({
  name: 'theme3',
  palette: {
    gradients: {
      background: `linear-gradient(to bottom, ${theme3Colors.purple1}, ${theme3Colors.purple2})`,
      button: `linear-gradient(to right, ${theme3Colors.purple1}, ${theme3Colors.purple2})`
    },
    background: {
      paper: theme3Colors.grey,
      default: theme3Colors.purple1
    },
    text: {
      secondary: theme3Colors.white
    },
    common: {
      white: theme3Colors.white
    },
    grey: {
      A100: theme3Colors.grey
    },
    primary: {
      main: theme3Colors.darkPurple,
      contrastText: theme3Colors.yellow,
      light: theme3Colors.neutral
    },
    secondary: {
      main: theme3Colors.yellow
    },
    info: {
      main: theme3Colors.darkPurple,
      contrastText: theme3Colors.dark,
      dark: theme3Colors.darkPurple
    },
    action: {
      selected: theme3Colors.grey
    }
  },
  typography: {
    fontFamily: theme3Fonts.inter,
    body1: {
      fontFamily: theme3Fonts.inter
    },
    caption: {
      fontFamily: theme3Fonts.interBold
    },
    button: {
      fontFamily: theme3Fonts.interBold
    }
  },
  overrides: {
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: 'none'
        }
      },
      text: {
        padding: '0'
      }
    }
  }
} as unknown as TTheme);
