import { createTheme } from '@material-ui/core/styles';
import { TTheme } from './index';

export const theme2Colors = {
  white: '#FFFFFF',
  grey: '#C4C4C4',
  red: '#E90000',
  green: '#006F00',
  darkGreen: '#004300',
  dark: '#3A3A3A',
  neutral: '#8F8F8F'
};

export const theme2Fonts = {
  inter: 'Inter, Roboto, Arial, sans-serif',
  interBold: 'InterBold, Roboto, Arial, sans-serif'
};
export const theme2 = createTheme({
  name: 'theme2',
  palette: {
    background: {
      paper: theme2Colors.white,
      default: theme2Colors.green
    },
    text: {
      secondary: theme2Colors.white
    },
    common: {
      white: theme2Colors.white
    },
    grey: {
      A100: theme2Colors.grey
    },
    primary: {
      main: theme2Colors.green,
      contrastText: theme2Colors.red,
      light: theme2Colors.neutral
    },
    secondary: {
      main: theme2Colors.red
    },
    info: {
      main: theme2Colors.green,
      contrastText: theme2Colors.green,
      dark: theme2Colors.darkGreen
    },
    action: {
      selected: theme2Colors.white
    }
  },
  typography: {
    fontFamily: theme2Fonts.inter,
    body1: {
      fontFamily: theme2Fonts.inter
    },
    caption: {
      fontFamily: theme2Fonts.interBold
    },
    button: {
      fontFamily: theme2Fonts.interBold
    }
  },
  overrides: {
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: 'none'
        }
      },
      text: {
        padding: '0'
      }
    }
  }
} as unknown as TTheme);
