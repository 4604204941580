//#region imports
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from '@material-ui/core/Drawer';

import stylesMenuMobile from './menu.mobile.styles';
import MenuMobileDrawer from './components/menu.mobile.drawer';
import { useTheme } from '@material-ui/core';
import { TTheme } from '../../configs/theme';

import srcImgLogo from '../../assets/svg/logo.svg';
import logoInverseImg from '../../assets/svg/logo.inverse.svg';
import { TMenuItems } from '../../models/menu';
//#endregion

type TProps = {
  readonly currentLocale: string;
  readonly items: TMenuItems[];
  changeLanguage(locale: string): void;
};

const MenuMobile: FC<TProps> = ({ items, currentLocale, changeLanguage }) => {
  const theme = useTheme<TTheme>();
  const classes = stylesMenuMobile({ isInverse: theme.name === 'theme1' });
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={ `${classes.root} ${theme.name}` }>
      <div className={ classes.container }>
        <span className={ `${ classes.menuButton } ${ classes.menu }` } onClick={ toggleDrawer }>
          { t('menu.menu') }
          <Drawer className={ `${theme.name}` } open={ isOpen } onClose={ toggleDrawer } variant="persistent">
            <MenuMobileDrawer items={ items } toggleDrawer={ toggleDrawer } changeLanguage={ changeLanguage } currentLocale={ currentLocale }/>
          </Drawer>
        </span>
        <div className={ classes.logoContainer }>
          <img alt={ '' } className={ classes.logo } src={ theme.name === 'theme1' ? srcImgLogo : logoInverseImg }/>
          <span className={ `header ${classes.logoText} ${theme.name}` } dangerouslySetInnerHTML={ { __html: t('menu.logo') } }/>
        </div>
        <span onClick={ () => changeLanguage(currentLocale === 'ru' ? 'kz' : 'ru') } className={ `${ classes.menuButton } ${ classes.locale }` }>
          { currentLocale === 'ru' ? 'kz' : 'ru' }
        </span>
      </div>
    </div>
  );
};

export default MenuMobile;
