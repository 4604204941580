//#region imports
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import { TTheme } from '../../../configs/theme';

import stylesCard from '../styles';
import useMobile from '../../../hooks/useMobile';
import question from '../../../assets/svg/question.svg';
//#endregion

const Feedback = () => {
  const theme = useTheme<TTheme>();
  const { isMobile } = useMobile();
  const classes = stylesCard({ isMobile });
  const { t } = useTranslation();

  return (
    <span style={ { padding: '17px 26px' } } className={ `${classes.container} ${ theme.name }` } id="feedback">
      <img alt={ '' } className={ classes.icon } src={ question }/>
      <span className={ classes.desc } dangerouslySetInnerHTML={ { __html: t('card.feedback') } }/>
    </span>
  );
};

export default Feedback;
