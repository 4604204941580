//#region imports
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TTheme } from '../../configs/theme';
//#endregion

export default makeStyles<TTheme>((theme: TTheme) => createStyles({
  root: {
    width: 'unset',
    margin: '0 20px',
    border: `1px solid ${ theme.palette.grey.A100 }`,
    borderRadius: 15,
    boxShadow: '0px 6px 4px rgba(0, 0, 0, 0.12)'
  }
}),
  { name: 'Wrapper' }
);
