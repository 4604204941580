const ru = {
  action: {
    play: 'Играть',
    subscribe: {
      theme1: 'Подключить'
    },
    support: 'Для получения подробной информации или решения возникшего вопроса позвоните на бесплатный номер \n' +
      '<a href="tel:8-800-080-9351">8-800-080-9351</a>'
  },
  menu: {
    logo: 'Национальная<br />лотерея',
    participate: 'Участвовать в лотерее',
    prizes: 'Призы',
    rules: 'Правила лотереи',
    feedback: 'Обратная связь',
    localeKz: 'kz',
    localeRu: 'ru',
    menu: 'МЕНЮ'
  },
  common: {
    titleApp: 'Национальная лотерея'
  },
  banner: {
    header: 'Выиграйте миллион <br/>в лотерее «OGO Удача»',
    desc: 'Моментальная лотерея с денежными призами для абонентов Kcell/activ! Подключите подписку за 100₸ в день, получайте билеты каждый день, выигрывайте и станьте новым миллионером! '
  },
  promo: {
    header: 'Первая мобильная лотерея с моментальными выигрышами',
    content: '<div class="line"><span class="bulit">1</span> Подключите подписку на официальную лотерею от «Сәтті Жұлдыз».</div><div class="line"><span class="bulit">2</span> Получайте по 1 лотерейному билету в SMS каждый день. Результат розыгрыша билета вы узнаете моментально.</div><div class="line"><span class="bulit">3</span> Продолжайте получать лотерейные билеты каждый день, чтобы выиграть миллион!</div>',
    desc: 'Вы сможете покупать дополнительные лотерейные билеты в SMS, чтобы увеличить шансы на крупный выигрыш! Выигрыш выплачивается в течение суток.'
  },
  prizes: {
    header: 'Призы до 1 000 000 ₸',
    label: 'Призовой фонд лотереи «OGO Удача»:',
    prize0: '1 000 призов по <b style="color: {{color}}">8 000 ₸</b>',
    prize1: '2 000 призов по <b style="color: {{color}}">4 000 ₸</b>',
    prize2: '4 000 призов по <b style="color: {{color}}">2 000 ₸</b>',
    prize3: '6 000 призов по <b style="color: {{color}}">1 000 ₸</b>',
    prize4: '30 000 призов по <b style="color: {{color}}">800 ₸</b>',
    prize5: '50 000 призов по <b style="color: {{color}}">600 ₸</b>',
    prize6: '200 000 призов по <b style="color: {{color}}">400 ₸</b>',
    prize7: '1 100 000 призов по <b style="color: {{color}}">200 ₸</b>',
    prize8: '1 460 000 призов по <b style="color: {{color}}">100 ₸</b>',
    superPrizeLabel: '3 супер-приза',
    superPrize: '1 000 000 ₸'
  },
  cards: {
    desc: 'Разработано совместно с национальной лотереей Республики Казахстан АО «Сәтті Жұлдыз»',
    desc2: 'Для получения подробной информации или решения возникшего вопроса позвоните на бесплатный номер \n' +
      '<a href="tel:8-800-080-9351">8-800-080-9351</a>'
  },
  rules: {
    header: 'Правила лотереи',
    rule0: 'Подключите подписку на лотерею «OGO Удача» и получайте по 1 лотерейному билету каждый день.',
    rule1: 'Билет разыгрывается моментально: Вы сразу узнаете, выиграли Вы или нет',
    rule2: 'Если Ваш билет выиграл, денежный приз будет мгновенно зачислен на Ваш абонентский счет. ' +
      'Если Вы выиграете супер-приз, мы свяжемся с Вами для передачи выигрыша.',
    rule3: 'Ваш билет не выиграл? Не расстраивайтесь! Вы можете купить дополнительный лотерейный билет и попытать удачу вновь.',
    rule4: 'Участвуйте в лотерее каждый день, чтобы увеличить свои шансы выиграть миллион!',
    button: 'Все правила лотереи'
  },
  modal: {
    subscribe: {
      title: 'Выиграйте миллион',
      desc: 'Ваш запрос принят. Подробности о подписке на лотерею «OGO Удача», лотерейные билеты и информацию о выигрыше ожидайте по SMS.',
      button: 'Хорошо!'
    },
    subscribeError: {
      title: 'Ошибка',
      desc: 'Не удалось оформить подписку на лотерею «OGO Удача»',
      button: 'Закрыть'
    },
    modalInfo: {
      0: {
        title: 'Участвуйте в лотерее',
        desc: `<p style='{{ style }}'>Получайте лотерейные билеты в SMS каждый день и попробуйте выиграть миллион!</p><p style='{{ style }}'>Для участия в лотерее «OGO Удача» для абонентов Kcell/activ у Вас должна быть оформлена подписка, стоимость 100 ₸/день.</p><p style='{{ style }}'><b>Для активации подписки</b> наберите на мобильном телефоне USSD <b><a href='tel:*88%23'>*88#</a></b></p><p style='{{ style }}'><b>Чтобы купить еще один или несколько билетов</b>, наберите *88*N#, где N количество билетов от 1 до 10. Например, чтобы купить 10 билетов, наберите <b><a href='tel:*88*10%23'>*88*10#</a></b> Стоимость каждого купленного билета 100 ₸.</p><p style='{{ style }}'>Для получения подробной информации или решения возникшего вопроса позвоните на бесплатный номер <a href='tel:8-800-080-9351'>8-800-080-9351</a></p>`,
        button: 'Хорошо!'
      },
      1: {
        title: 'Участвуйте в лотерее',
        desc: `<p style='{{ style }}'>Получайте лотерейные билеты в SMS каждый день и попробуйте выиграть миллион!</p><p style='{{ style }}'>Для участия в лотерее «OGO Удача» для абонентов Kcell/activ у Вас должна быть оформлена подписка, стоимость 100 ₸/день.</p><p style='{{ style }}'><b>Для активации подписки</b> наберите на мобильном телефоне USSD <b><a href='tel:*88%23'>*88#</a></b></p><p style='{{ style }}'><b>Чтобы купить еще один или несколько билетов</b>, наберите *88*N#, где N количество билетов от 1 до 10. Например, чтобы купить 10 билетов, наберите <b><a href='tel:*88*10%23'>*88*10#</a></b> Стоимость каждого купленного билета 100 ₸.</p><p style='{{ style }}'>Для получения подробной информации или решения возникшего вопроса позвоните на бесплатный номер <a href='tel:8-800-080-9351'>8-800-080-9351</a></p>`,
        button: 'Хорошо!'
      },
      2: {
        title: 'Участвуйте в лотерее',
        desc: `<p style='{{ style }}'>Подписка на лотерею доступна абонентам Tele2, Altel и Beeline, стоимость 100 ₸/день.</p><p style='{{ style }}'>Если вы являетесь абонентом Tele2 или Altel, <b> для активации подписки</b> наберите на мобильном телефоне USSD <b><a href='tel:*9351'>*9351#</a></b></p><p style='{{ style }}'>Если вы являетесь абонентом Beeline, <b>для активации подписки</b>, наберите на мобильном телефоне USSD <b><a href='tel:*88#%23'>*88#</a></b></p><p style='{{ style }}'>Для получения подробной информации или решения возникшего вопроса позвоните на бесплатный номер <a href='tel:8-800-080-9351'>8-800-080-9351</a></p>`,
        button: 'Хорошо!'
      }
    }
  },
  card: {
    desc: 'Разработано совместно с национальной лотереей Республики Казахстан АО «Сәтті Жұлдыз»',
    feedback: 'Для получения подробной информации или решения возникшего вопроса позвоните на бесплатный номер \n' +
      '<a href="tel:8-800-080-9351">8&#8209;800&#8209;080&#8209;9351</a>'
  },
  footer: {
    copyright: `${new Date().getFullYear()} © АО «Сәтті Жұлдыз»`,
    feedback: 'Обратная связь',
    terms: 'Пользовательское соглашение',
    desc: 'СОЦИАЛЬНАЯ ОТВЕТСТВЕННОСТЬ: <br/><br/> 10% от операционного игрового дохода перечисляется на развитие отечественного спорта. 10% от операционного игрового дохода перечисляется в Общественный Фонд «Қазақстан Халқына».'
  }
};

export default ru;
