//#region imports
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { ThemeProvider } from '@material-ui/core';

import fetchSubscribe from './entities/subscribe';
import { theme1, theme2, theme3, theme4 } from './configs/theme';

import './assets/css/reset.css';
import './assets/css/font.styles.css';
import './assets/css/base.styles.css';

import Mobile from './views/Mobile';
import Modal from './components/Modal';
import { TMenuItems } from './models/menu';
import Loader from './components/loader/loader';
//#endregion

export type THeadersContext = {
  headers: any
  setHeaders: (p: any) => void
};
export const HeadersContext = React.createContext<THeadersContext>({
  headers: {},
  setHeaders: () => {}
});

export const useHeadersContext = () => React.useContext(HeadersContext);

const themes = [theme1, theme2, theme3, theme4];

const App = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [currentLocale, setCurrentLocale] = React.useState<string>('ru');
  const [request, setRequest] = React.useState<{ message: string; status: string; }>({ message: '', status: '' });
  const [hasVisibleModal, setModal] = React.useState<boolean>(false);
  const [theme, setTheme] = React.useState(theme1);
  const [headers, setHeaders] = React.useState<{ [key: string]: string; }>({});

  const searchQuery = new URLSearchParams(location.search);
  const lang = searchQuery?.get('lang') === 'kz' ? 'kz' : 'ru';
  let textId = searchQuery?.get('text_id') || searchQuery?.get('textid') || searchQuery?.get('textId') || '';
  textId = (textId && (textId === '1' || textId === '2') && textId) || '1';
  const partId = searchQuery?.get('part_id') || searchQuery?.get('partid') || searchQuery?.get('partId');
  const designId = searchQuery?.get('design') || '1';
  const hasHiddenTheme = searchQuery?.get('hidden') === 'true';
  const clickId = searchQuery?.get('click_id') || searchQuery?.get('clickid') || searchQuery?.get('clickId') || '';
  const hasMsisdn = Number(headers?.['x-has-msisdn']) === 1 || searchQuery?.get('hasMsisdn') === 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9';
  const menuItems: TMenuItems[] = [
    { number: 1, title: t('menu.participate'), link: '#participate' },
    { number: 2, title: t('menu.prizes'), link: '#prizes' },
    { number: 3, title: t('menu.rules'), link: '#rules' },
    { number: 4, title: t('menu.feedback'), link: '#feedback' }
  ];

  const toggleModal = React.useCallback(() => {
    setModal(!hasVisibleModal);
    document.body.style.overflow = hasVisibleModal ? 'hidden' : 'auto';
  }, [hasVisibleModal]);

  const handlerSubscribe = React.useCallback(async () => {
    if (hasMsisdn) {
      await fetchSubscribe({
        successCallback: () => {
          setRequest({ message: 'Success', status: 'ok' });
          toggleModal();
        },
        failureCallback: () => {
          setRequest({ message: 'Failure', status: 'err' });
          toggleModal();
        },
        loadingCallback: () => {
          setRequest({ message: 'Loading', status: 'loading' });
        },
        clickId: clickId || '',
        partId: partId || ''
      });
    } else {
      setRequest({ message: 'Failure', status: 'err' });
      toggleModal();
    }
  }, [hasMsisdn, partId, clickId]);

  React.useEffect(() => {
    if (hasHiddenTheme && hasMsisdn) {
      handlerSubscribe();
    }
  }, [hasHiddenTheme, hasMsisdn]);

  React.useEffect(() => {
    if (hasMsisdn) {
      // @ts-ignore
      // window.ym(93217884, 'reachGoal', 'header_enrichment_success');
    }
  }, [hasMsisdn]);
  React.useEffect(() => {
    if (lang) {
      setCurrentLocale(lang);
    }
  }, [lang]);
  React.useEffect(() => {
    if (currentLocale) {
      i18n.changeLanguage(currentLocale);
    }
  }, [currentLocale]);

  React.useEffect(() => {
    document.title = t('common.titleApp');
  }, [partId, t]);

  React.useEffect(() => {
    setTheme(themes[0]);
  }, [designId]);

  React.useEffect(() => {
    setTheme(themes[0]);
    document.body.className = `theme${1}`;
  }, [theme]);

  React.useEffect(() => {
    document.title = t('common.titleApp');
    const req = new XMLHttpRequest();
    req.open('GET', document.location.origin, false);
    req.send(null);
    const getHeaders = req.getAllResponseHeaders().toLowerCase();
    const curHeaders = getHeaders.split(/\n|\r|\r\n/g).reduce((a: { [key: string]: string }, b) => {
      if (b.length) {
        const [key, value] = b.split(': ');
        a[key] = value;
      }
      return a;
    }, {});
    setHeaders(curHeaders);
  }, []);

  return (
    <HeadersContext.Provider value={ { headers, setHeaders } }>
      <ThemeProvider theme={ theme }>
        <Modal
          request={ request }
          textId={ textId }
          hasHiddenTheme={ hasHiddenTheme }
          hasMsisdn={ hasMsisdn }
          hasVisible={ hasVisibleModal }
          toggleModal={ toggleModal }
        />
        {
          hasHiddenTheme
            ? request.status === 'loading' &&
              <Loader />
            :
              <Mobile
                partId={ partId as string }
                clickId={ clickId }
                textId={ textId }
                hidden={ hasHiddenTheme }
                menuItems={ menuItems }
                designId={ designId }
                handlerSubscribe={ handlerSubscribe }
                request={ request }
                changeLanguage={ locale => setCurrentLocale(locale) }
                currentLocale={ currentLocale }
              />
        }
      </ThemeProvider>
    </HeadersContext.Provider>
  );
};

export default App;
