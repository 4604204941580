//#region imports
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import { TTheme } from '../../../configs/theme';

import stylesCard from '../styles';
import useMobile from '../../../hooks/useMobile';

import imgLogo from '../../../assets/svg/logo.inverse.svg';
//#endregion

const Description = () => {
  const theme = useTheme<TTheme>();
  const { isMobile } = useMobile();
  const classes = stylesCard({ isMobile });
  const { t } = useTranslation();

  const logoBlock = (
    <>
      <img alt={ '' } className={ classes.icon } src={ imgLogo }/>
      <div className={ classes.desc }>{ t('card.desc') }</div>
    </>
  );

  return (
    <span className={ `${classes.container} ${theme.name} ${theme.name === 'theme3' ? 'primary' : ''}` }>
      { logoBlock }
    </span>
  );
};

export default Description;
