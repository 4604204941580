//#region imports
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';

import useMobile from '../../hooks/useMobile';
import styles from './rules.styles';
import Button from '../Button';
import Wrapper from '../../components/wrapper/wrapper';

import { TTheme } from '../../configs/theme';
import { TRequest } from '../../models/request';
//#endregion

type TProps = {
  readonly request: TRequest;
  readonly designId: string;
  handlerSubscribe(): void;
};

const Rules: React.FC<TProps> = ({ handlerSubscribe, request, designId }) => {
  const theme = useTheme<TTheme>();
  const { i18n } = useTranslation();

  const { isMobile } = useMobile();
  const { t } = useTranslation();
  const classes = styles({ i18n });

  const rulesList = [...Array(5)].map((el, i) => {
    return (
      <div className={ `${classes.item} ${theme.name}` } key={ i }>
        <span className={ `${ classes.icon }` }>{ i + 1 }</span>
        <span>{ t(`rules.rule${i}`) }</span>
      </div>
    );
  });

  const handlerSubscribeButton = () => {
    handlerSubscribe();
    // @ts-ignore
    // window.ym(93217884, 'reachGoal', `design${designId}_rules_subscription_click`);
  };

  return (
    <>
      { theme.name !== 'theme2' && theme.name !== 'theme4' && <div className={ classes.picture }/> }
      <Wrapper className={ classes.root }>
        <h3 className={ `${classes.header} ${theme.name}` } id="rules">{ t('rules.header') }</h3>
          <section className={ classes.container }>
            { rulesList }
          <div className={ `${classes.buttons} ${theme.name}` }>
            <Button
              readonly={ request.status === 'ok' }
              disabled={ request.status === 'loading' }
              variant={ 'primary' }
              title={ t(`action.${ theme.name === 'theme4' ? 'play' : `subscribe.${theme.name}` }`) }
              isMobile={ isMobile }
              onClick={ handlerSubscribeButton }
            />
            <Button
              className={ 'outlined' }
              variant={ 'outlined' }
              title={ t('rules.button') }
              isMobile={ isMobile }
              link={ `/rules_${i18n.language}.pdf` }
            />
          </div>
          </section>
      </Wrapper>
    </>
  );
};

export default Rules;
