//#region imports
import React, { FC, useMemo } from 'react';
import Drawer from '@material-ui/core/Drawer';

import useMobile from '../../hooks/useMobile';
import stylesModal from './styles';

import ModalContent from './Content';
import ModalError from './Error';
import { TRequest } from '../../models/request';
//#endregion

type TProps = {
  readonly request: TRequest;
  readonly hasVisible: boolean;
  readonly hasHiddenTheme?: boolean;
  readonly hasMsisdn?: boolean;
  readonly textId?: string;
  toggleModal(): void;
};

const Modal: FC<TProps> = ({
  request,
  toggleModal,
  hasVisible,
  hasHiddenTheme = true,
  hasMsisdn,
  textId
}) => {
  const { isMobile } = useMobile();
  const classes = stylesModal({ isMobile });

  const renderContent = useMemo(() => {
    if (hasVisible) {
      if (request.status === 'ok') {
        return (
          <ModalContent
            status={ request.status }
            toggleModal={
              hasHiddenTheme
                ? undefined
                : toggleModal
            }
          />
        );
      }
      if (request.status === 'err') {
        return (
          <ModalError
            status={ request.status }
            keyMessage={ `modalInfo.${textId}` }
            toggleModal={ hasHiddenTheme ? undefined : toggleModal }
          />
        );
      }
    }
  }, [hasVisible, hasHiddenTheme, textId, request]);

  if (!hasVisible) {
    return null;
  }

  return (
    <Drawer
      className={ classes.wrapperMobile }
      open={ hasVisible }
      onClose={ toggleModal }
    >
      { renderContent }
    </Drawer>
  );
};

export default Modal;
