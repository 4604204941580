//#region imports
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';

import i18n from './configs/locales';
import App from './app';
//#endregion

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el as HTMLElement);

root.render(
  <Router>
    <I18nextProvider i18n={ i18n }>
      <App />
    </I18nextProvider>
  </Router>
);
