const kz = {
  action: {
    participate: 'Қосу',
    play: 'Ойнау',
    subscribe: {
      theme1: 'Қосу'
    },
    support: 'Толық ақпарат алу үшін немесе туындаған мәселені шешу үшін \n' +
      '<a href="tel:8-800-080-9351">8-800-080-9351</a> тегін нөміріне қоңырау шалыңыз'
  },
  menu: {
    logo: 'Ұлттық<br />лотерея',
    participate: 'Лотереяға қатысу',
    prizes: 'Жүлделер',
    rules: 'Лотерея ережелері',
    feedback: 'Кері байланыс',
    localeKz: 'kz',
    localeRu: 'ru',
    menu: 'МӘЗІР'
  },
  common: {
    titleApp: 'Ұлттық лотерея'
  },
  banner: {
    header: '«OGO Сәттілік» <br/> лотереяысында миллион <br/> ұтып алыңыз',
    desc: 'Kcell/activ абоненттері үшін ақшалай ұтысы бар лездік лотерея! Күніне 100₸ жазылымға жазылыңыз, күн сайын билеттер алыңыз, ұтып алып жаңа миллионер болыңыз!'
  },
  promo: {
    header: 'Лездік ұтыстары бар алғашқы мобильді лотерея',
    content: '<div class="line"><span class="bulit">1</span> «Сәтті Жұлдыздан» ресми лотереяға жазылуды қосыңыз.</div><div class="line"><span class="bulit">2</span> Күн сайын SMS арқылы 1 лотерея билетін алыңыз. Билет ұтысының нәтижесін бірден білетін боласыз.</div><div class="line"><span class="bulit">3</span> Миллион ұтып алу үшін күн сайын лотерея билеттерін алуды жалғастырыңыз!</div>',
    desc: 'Үлкен ұтыс алу мүмкіндігіңізді арттыру үшін сіз SMS арқылы қосымша лотерея билеттерін сатып ала аласыз! Ұтыстар бір тәулік ішінде төленеді.'
  },
  prizes: {
    header: '1 000 000 ₸ дейінгі жүлделер',
    label: '«OGO Сәттілік» лотереяысының жүлде қоры:',
    prize0: '<b style="color: {{color}}">8 000 ₸</b> 1 000 жүлде ',
    prize1: '<b style="color: {{color}}">4 000 ₸</b> 2 000 жүлде',
    prize2: '<b style="color: {{color}}">2 000 ₸</b> 4 000 жүлде',
    prize3: '<b style="color: {{color}}">1 000 ₸</b> 6 000 жүлде',
    prize4: '<b style="color: {{color}}">800 ₸</b> 30 000 жүлде',
    prize5: '<b style="color: {{color}}">600 ₸</b> 50 000 жүлде',
    prize6: '<b style="color: {{color}}">400 ₸</b> 200 000 жүлде',
    prize7: '<b style="color: {{color}}">200 ₸</b> 1 100 000 жүлде',
    prize8: '<b style="color: {{color}}">100 ₸</b> 1 460 000 жүлде',
    superPrizeLabel: '3 супер-жүлде',
    superPrize: '1 000 000 ₸'
  },
  cards: {
    desc1: '«OGO Сәттілік» лотереясы Қазақстан Республикасының «Сәтті Жұлдыз» \n' +
      'АҚ ұлттық лотереясымен әзірлеген.',
    desc2: 'Толық ақпарат алу үшін немесе туындаған мәселені шешу үшін \n' +
      '<a href="tel:8-800-080-9351">8-800-080-9351</a> тегін нөміріне қоңырау шалыңыз'
  },
  rules: {
    header: 'Лотерея ережелері',
    rule0: '«OGO Сәттілік» лотереясына жазылымды қосыңыз және күн сайын 1 лотерея билетін алып тұрыңыз.',
    rule1: 'Билет бірден ойнатылады: Сіз ұттыңыз ба, жоқ па, бірден білесіз.',
    rule2: 'Егер Сіздің билетіңіз ұтса, ақшалай жүлде Сіздің абоненттік шотыңызға бірден аударылады.. ' +
      'Егер Сіз супер-жүлдені ұтып алсаңыз, ұтысты тапсыру үшін Сізбен хабарласамыз.',
    rule3: 'Сіздің билетіңіз ұтпады ма? Қапа болмаңыз! Сіз қосымша лотерея билетін сатып алып, бағыңызды қайта сынай аласыз.',
    rule4: 'Миллион ұтып алу мүмкіндігіңізді арттыру үшін күн сайын лотерея ойнаңыз!',
    button: 'Лотереяның барлық ережелері'
  },
  modal: {
    subscribe: {
      title: 'Миллион ұтыңыз',
      desc: 'Сіздің өтінішіңіз қабылданды. «OGO Сәттілік» лотереясына жазылу, лотерея билеттері және ұтыстар туралы ақпарат SMS-пен келеді.',
      button: 'Жарайды!'
    },
    subscribeError: {
      title: 'Қате',
      desc: '«OGO Сәттілік» лотереясына жазылу сәтсіз аяқталды',
      button: 'Жарайды!'
    },
    modalInfo: {
      0: {
        title: 'Лотереяға қатысыңыз',
        desc: `<p style='{{ style }}'>Күн сайын SMS арқылы лотерея билеттерін алыңыз және миллион ұтуға тырысып көріңіз!</p><p style='{{ style }}'>Kcell/activ абоненттері үшін «OGO Сәттілік» лотереясына қатысу үшін сізде жазылым болуы керек, құны 100 ₸/күн</p><p style='{{ style }}'><b>Жазылымды қосу</b> үшін ұялы телефонда <b><a href='tel:*88%23'>*88#</a></b> USSD теріңіз.</p><p style='{{ style }}'><b>Тағы бір немесе бірнеше билет сатып</b> алу үшін, *88*N# командасын теріңіз, мұндағы N - 1-ден 10-ға дейінгі билеттер саны. Мысалы, 10 билет сатып алу үшін, <b><a href='tel:*88*10%23'>*88*10#</a></b> командасын теріңіз. Сатып алынған әр билеттің құны 100 ₸.</p><p style='{{ style }}'>Нақты ақпарат алу немесе туындаған мәселені шешу үшін <a href='tel:8-800-080-9351'>8-800-080-9351</a> тегін нөміріне соғыңыз.</p>`,
        button: 'Жарайды!'
      },
      1: {
        title: 'Лотереяға қатысыңыз',
        desc: `<p style='{{ style }}'>Күн сайын SMS арқылы лотерея билеттерін алыңыз және миллион ұтуға тырысып көріңіз!</p><p style='{{ style }}'>Kcell/activ абоненттері үшін «OGO Сәттілік» лотереясына қатысу үшін сізде жазылым болуы керек, құны 100 ₸/күн</p><p style='{{ style }}'><b>Жазылымды қосу</b> үшін ұялы телефонда <b><a href='tel:*88%23'>*88#</a></b> USSD теріңіз.</p><p style='{{ style }}'><b>Тағы бір немесе бірнеше билет сатып</b> алу үшін, *88*N# командасын теріңіз, мұндағы N - 1-ден 10-ға дейінгі билеттер саны. Мысалы, 10 билет сатып алу үшін, <b><a href='tel:*88*10%23'>*88*10#</a></b> командасын теріңіз. Сатып алынған әр билеттің құны 100 ₸.</p><p style='{{ style }}'>Нақты ақпарат алу немесе туындаған мәселені шешу үшін <a href='tel:8-800-080-9351'>8-800-080-9351</a> тегін нөміріне соғыңыз.</p>`,
        button: 'Жарайды!'
      }
    }
  },
  card: {
    desc: '«OGO Сәттілік» лотереясы Қазақстан Республикасының «Сәтті Жұлдыз» АҚ ұлттық лотереясымен әзірлеген.',
    feedback: 'Толық ақпарат алу үшін немесе туындаған мәселені шешу үшін \n' +
      '<a href="tel:8-800-080-9351">8&#8209;800&#8209;080&#8209;9351</a> тегін нөміріне қоңырау шалыңыз.'
  },
  footer: {
    copyright: `${new Date().getFullYear()} © «Сәтті Жұлдыз» АҚ`,
    feedback: 'Кері байланыс',
    terms: 'Пайдалану шарттары',
    desc: 'ӘЛЕУМЕТТІК ЖАУАПКЕРШІЛІК: <br/><br/> Операциялық ойыннан түскен табыстың 10% отандық спортты дамытуға аударылады. Операциялық ойыннан түскен табыстың 10% «Қазақстан халқына» қоғамдық қорына аударылады.'
  }
};

export default kz;
