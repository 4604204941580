//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@material-ui/core';
import { TTheme } from '../../configs/theme';

import styles from './styles';
import useMobile from '../../hooks/useMobile';
import closeDark from '../../assets/svg/close.dark.svg';
import Button from '../Button';
//#endregion

interface IModalContentProps {
  status?: string;
  toggleModal?: () => void;
}

const Content: FC<IModalContentProps> = ({ toggleModal, status }) => {
  const theme = useTheme<TTheme>();
  const { isMobile } = useMobile();
  const { t } = useTranslation();
  const classes = styles({ isMobile });

  return (
    <Box height="100vh" display="flex" flexDirection="column" className={ `${classes.content} ${theme.name} ${status}` }>
      <div className={ classes.header }>
        <h3 className={ classes.title } dangerouslySetInnerHTML={ { __html: t('modal.subscribe.title') } }/>
        { toggleModal && <img alt={ '' } className={ classes.close } src={ closeDark } onClick={ toggleModal }/> }
      </div>
      <div className={ classes.desc } dangerouslySetInnerHTML={ { __html: t('modal.subscribe.desc') } }/>
      <div className={ classes.button }>
        {
          toggleModal &&
            <Button
              title={ t('modal.subscribe.button') }
              isMobile={ isMobile }
              onClick={ toggleModal }
            />
        }
      </div>
    </Box>
  );
};

export default Content;
