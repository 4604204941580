//#region imports
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TTheme } from '../../configs/theme';
import { TranslationProps } from 'react-i18next';

import srcImgCoins from '../../assets/img/coins.webp';
//#endregion

export default makeStyles<TTheme, { i18n: TranslationProps['i18n'] }>((theme: TTheme) => createStyles({
  root: {
    marginTop: (theme.name === 'theme2' ? 20 : (theme.name === 'theme4' ? 28 : 0)),
    marginBottom: 20
  },
  picture: {
    minHeight: '35vw',
    background: `top / cover no-repeat url(${srcImgCoins})`,
    margin: '0 20px',
    backgroundPosition: '0 -17px'
  },
  header: {
    height: 72,
    padding: '0 22px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.main,
    fontSize: 24,
    fontFamily: theme.typography.caption.fontFamily,
    lineHeight: '72px',
    borderRadius: '15px 15px 0 0',
    '&.theme4': {
      textAlign: 'center'
    }
  },
  container: {
    padding: '26px 20px 18px',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 20,
    height: 20,
    marginRight: 15,
    marginTop: 6,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    border: `2px solid ${ theme.palette.secondary.main }`,
    borderRadius: '50%'
  },
  iconReverse: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    border: `2px solid ${ theme.palette.secondary.main }`
  },
  item: {
    display: 'flex',
    marginBottom: 20.25,
    fontSize: 16,
    lineHeight: '20px',
    textSizeAdjust: '100%',
    fontFamily: theme.typography.fontFamily
  },
  buttons: {
    gap: 24,
    width: '100%',
    margin: '11px 0 20px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& .MuiButton-label': {
      fontSize: 21
    },
    '& .outlined .MuiButton-label': {
      fontSize: ({ i18n }) => i18n?.language === 'kz' ? 18 : 21
    }
  }
}),
  { name: 'Rules' }
);
