//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import useMobile from '../../hooks/useMobile';

import { TTheme } from '../../configs/theme';
import closeDark from '../../assets/svg/close.dark.svg';

import Button from '../Button';
import Box from '@material-ui/core/Box';

import styles from './styles';
//#endregion

interface IModalContentProps {
  keyMessage?: string;
  status?: string;
  toggleModal?: () => void;
}

const Error: FC<IModalContentProps> = ({ toggleModal, keyMessage, status }) => {
  const { isMobile } = useMobile();
  const { t } = useTranslation();
  const classes = styles({ isMobile });
  const theme = useTheme<TTheme>();

  React.useEffect(() => {
    // @ts-ignore
    // window.ym(93217884, 'reachGoal', 'error_popup');
  }, []);

  return (
    <Box height="100vh" display="flex" flexDirection="column" className={ `${classes.content} ${theme.name} ${status}` }>
      <div className={ classes.header }>
        <h3 className={ classes.title }>{ t(`modal.${keyMessage}.title`) }</h3>
        { toggleModal && <img alt={ '' } className={ classes.close } src={ closeDark } onClick={ toggleModal }/> }
      </div>
      <div className={ classes.desc } dangerouslySetInnerHTML={ { __html: t(`modal.${keyMessage}.desc`, { style: 'margin-bottom: 20px' }) } }/>
      <div className={ classes.button }>
        {
          toggleModal &&
            <Button
              fullWidth
              style={ { maxWidth: 175 } }
              title={ t(`modal.${keyMessage}.button`) }
              isMobile={ isMobile }
              onClick={ toggleModal }
            />
        }
      </div>
    </Box>
  );
};

export default Error;
