//#region imports
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TTheme } from '../../configs/theme';
//#endregion

export default makeStyles<TTheme>((theme: TTheme) => createStyles({
  wrapper: {
    margin: '25px 20px 0 20px',
    paddingTop: '12px',
    borderTop: `1px solid ${ theme.palette.text.disabled }`
  },
  item: {
    display: 'block',
    margin: '5px 0 13.5px',
    color: theme.palette.text.disabled,
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: 14,
    lineHeight: '17.09px'
  }
}),
  { name: 'Footer' }
);
