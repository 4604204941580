//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import { HashLink as Link } from 'react-router-hash-link';

import stylesMenuMobile from '../menu.mobile.styles';
import logoInverse from '../../../assets/svg/logo.inverse.svg';
import closeLight from '../../../assets/svg/close.light.svg';
import { TTheme } from '../../../configs/theme';
import { TMenuItems } from '../../../models/menu';
//#endregion

interface IDrawerProps {
  readonly items: TMenuItems[];
  readonly currentLocale: string;
  changeLanguage(locale: string): void;
  toggleDrawer(): void;
}

const MenuMobileDrawer: FC<IDrawerProps> = ({ items, toggleDrawer, changeLanguage, currentLocale }) => {
  const theme = useTheme<TTheme>();
  const { t } = useTranslation();
  const classes = stylesMenuMobile({ isInverse: true });

  const localesList = ['kz', 'ru'].map((locale: string) => {
    return (
      <span
        key={ locale }
        onClick={ () => changeLanguage(locale) }
        className={ `${ classes.button } ${ locale !== currentLocale ? classes.activeButton : '' }` }
      >
        { locale }
      </span>
    );
  });

  return (
    <div className={ classes.drawer }>
      <div className={ classes.header }>
        <div className={ classes.header }>
          <img alt={ '' } className={ classes.logo } src={ logoInverse }/>
          <span className={ `${classes.logoText} ${theme.name}` } dangerouslySetInnerHTML={ { __html: t('menu.logo') } }/>
        </div>
        <img alt={ '' } className={ classes.close } src={ closeLight } onClick={ toggleDrawer }/>
      </div>
      <div className={ classes.list }>
      { items.map(el => {
        return (
          el.action ?
            <div key={ el.number } onClick={ el.action } className={ classes.link }>
              { el.title }
            </div> :
            <Link
              key={ el.number }
              className={ classes.link }
              to={ el.link as string }
              onClick={ toggleDrawer }
            >
            { el.title }
            </Link>
          );
        }
      ) }
      </div>
      <div className={ classes.buttons }>
        { localesList }
      </div>
    </div>
  );
};

export default MenuMobileDrawer;
