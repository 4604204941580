//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import useMobile from '../../hooks/useMobile';

import { TRequest } from '../../models/request';
import { TTheme } from '../../configs/theme';

import Button from '../Button';
import styles from './styles';
import bannerImg from '../../assets/img/theme1_banner_background.webp';
//#endregion

type TProps = {
  readonly request: TRequest;
  readonly designId: string;
  handlerSubscribe(): void;
};

const Banner: FC<TProps> = ({ handlerSubscribe, request, designId }) => {
  const theme = useTheme<TTheme>();

  const { isMobile } = useMobile();
  const { t, i18n } = useTranslation();
  const classes = styles({ isMobile });

  const handlerSubscribeButton = () => {
    handlerSubscribe();
  };

  // @ts-ignore
  return (
    <React.Fragment>
      <section className={ `${classes.root} ${theme.name}` }>
        <div className={ classes.container }>
          { theme.name === 'theme1'
            ?
              <h1
                className={ `${classes.header} ${theme.name}` }
                dangerouslySetInnerHTML={ { __html: t('banner.header') } }
              />
              : null
          }
          {
            theme.name === 'theme1'
              ?
                <div className={ classes.content }>
                  { <img src={ bannerImg } alt="" className={ classes.picture }/> }
                  <div className={ classes.text }>
                    <div
                      className={ classes.desc }
                      dangerouslySetInnerHTML={ { __html: t('banner.desc') } }
                    />
                    <br />
                  </div>
                  <Button
                    readonly={ request.status === 'ok' }
                    disabled={ request.status === 'loading' }
                    variant={ 'primaryContrast' }
                    title={ t(`action.subscribe.${theme.name}`) }
                    isMobile={ isMobile }
                    style={ { maxWidth: 180, marginBottom: 15 } }
                    onClick={ handlerSubscribeButton }
                  />
                </div>
              : null
          }
        </div>
      </section>
    </React.Fragment>
  );
};

export default Banner;
