//#region imports
import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TMenuItems } from '../models/menu';
import { TRequest } from '../models/request';

import Loader from '../components/loader/loader';
import Banner from '../components/Banner';
import Prizes from '../components/Prizes';
import Feedback from '../components/Card/components/Feedback';
import Description from '../components/Card/components/Description';
import Footer from '../components/Footer';
import CheckHeaders from '../components/CheckHeaders';
import MenuMobile from '../components/menu.mobile/menu.mobile';
import Rules from '../components/rules/rules';
//#endregion

type TProps = {
  readonly request: TRequest;
  readonly designId: string;
  readonly menuItems: TMenuItems[];
  readonly currentLocale: string;
  readonly clickId: string;
  readonly partId: string;
  readonly textId: string;
  readonly hidden: boolean;
  changeLanguage(locale: string): void;
  handlerSubscribe(): void;
};

const Mobile = ({
  clickId,
  partId,
  request,
  designId,
  handlerSubscribe,
  currentLocale,
  changeLanguage,
  menuItems,
  textId,
  hidden
}: TProps) => {
  const { t } = useTranslation();
  const [clickCount, setClickCount] = React.useState(0);
  const onClick = () => {
    clickCount === 10 ? setClickCount(0) : setClickCount(clickCount + 1);
  };

  useEffect(() => {
    document.title = t('common.titleApp');
  }, [t]);

  return (
    <>
      <MenuMobile currentLocale={ currentLocale } changeLanguage={ changeLanguage } items={ menuItems }/>
      <div onClick={ onClick }>
        <Banner request={ request } handlerSubscribe={ handlerSubscribe } designId={ designId }/>
        <CheckHeaders
          textId={ textId }
          hidden={ hidden }
          clickCount={ clickCount }
          setClickCount={ setClickCount }
          clickId={ clickId }
          partId={ partId }
        />
      </div>
      <Suspense fallback={ <Loader /> }>
        <Prizes request={ request } handlerSubscribe={ handlerSubscribe } designId={ designId }/>
        <Description />
        <Rules request={ request } handlerSubscribe={ handlerSubscribe } designId={ designId }/>
        <Feedback />
        <Footer />
      </Suspense>
    </>
  );
};

export default Mobile;
