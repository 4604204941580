//#region imports
import React, { FC } from 'react';
import ButtonUi, { ButtonProps } from '@material-ui/core/Button';
import isFunction from 'lodash/isFunction';
import CircularProgress from '@material-ui/core/CircularProgress';
import { EButtonVariant } from '../../models/button';

import styles from './styles';
//#endregion

type TProps = {
  title: string;
  isMobile: boolean;
  className?: string;
  link?: string;
  variant?: EButtonVariant | keyof typeof EButtonVariant;
  onClick?: () => void;
  fullWidth?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  style?: React.CSSProperties;
  size?: ButtonProps['size'];
};

const Button: FC<TProps> = ({
  title,
  isMobile,
  className,
  link,
  variant = EButtonVariant.primary,
  onClick,
  disabled = false,
  fullWidth = false,
  readonly = false,
  style,
  size
}) => {
  const classes = styles({ isMobile });

  const handleModal = () => {
    if (link) {
      window.open(link, '_blank', 'noopener');
    } else isFunction(onClick) && onClick();
  };

  return (
    <ButtonUi
      disabled={ disabled || readonly }
      fullWidth={ fullWidth }
      className={ `${className} ${classes.button} ${classes[variant]} ${disabled || readonly ? 'disabled' : ''}` }
      onClick={ handleModal }
      style={ style }
      size={ size }
    >
      { disabled ? <CircularProgress style={ { color: 'white' } } size={ 24 }/> : title }
    </ButtonUi>
  );
};

export default Button;
