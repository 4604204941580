//#region imports
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TTheme } from '../../configs/theme';
//#endregion

export default makeStyles<TTheme>((theme: TTheme) => createStyles({
  button: {
    height: 45,
    fontFamily: theme.typography.body1.fontFamily,
    borderRadius: 24,
    boxShadow: '0px 4px 4px rgba(31, 34, 41, 0.25)',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '13px',
    margin: '0 auto',
    '.theme1 &': {
      maxWidth: 278,
      width: '100%'
    },
    '& .MuiButton-label': {
      textTransform: 'none',
      fontSize: 21,
      fontWeight: 900,
      [theme.breakpoints.down(420)]: {
        padding: '25px 0'
      }
    },
    '&.disabled': {
      pointerEvents: 'none',
      backgroundColor: theme.palette.text.disabled,
      cursor: 'default',
      '&:hover': {
        backgroundColor: theme.palette.text.disabled
      }
    }
  },
  primary: {
    background: theme.name === 'theme3' ? theme.palette.gradients?.button : theme.palette.primary.main,
    '& .MuiButton-label': {
      color: theme.palette.common.white
    }
  },
  primaryContrast: {
    backgroundColor: theme.palette.primary.contrastText,
    border: theme.name === 'theme3' ? `2px solid ${theme.palette.common.white}` : 'none',
    '& .MuiButton-label': {
      color: theme.name === 'theme3' ? theme.palette.common.black : theme.palette.common.white
    },
    '&:hover': {
      backgroundColor: theme.name === 'theme4' ? theme.palette.success.main : theme.palette.primary.contrastText
    }
  },
  secondary: {
    backgroundColor: theme.palette.common.white,
    '& .MuiButton-label': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white
    }
  },
  info: {
    backgroundColor: theme.palette.info.main,
    '& .MuiButton-label': {
      color: theme.palette.common.white
    },
    '&:hover': {
      backgroundColor: theme.palette.info.main
    }
  },
  outlined: {
    backgroundColor: theme.palette.action.selected,
    border: theme.name === 'theme2' ? `1px solid ${theme.palette.primary.main}` : 0,
    '& .MuiButton-label': {
      color: theme.palette.info.contrastText
    },
    '&:hover': {
      backgroundColor: theme.palette.action.selected
    }
  }
}),
  { name: 'Button' }
);
