import isEmpty from 'lodash/isEmpty';

const fetchSubscribe: any = async (
  data: {
    successCallback?: () => void,
    failureCallback?: () => void,
    loadingCallback?: () => void,
    partId?: string;
    clickId?: string;
  },
  mock: {
    failure?: boolean;
  } = {}
) => {
  if (!isEmpty(mock)) {
    if (mock.failure) {
      data.failureCallback && await data.failureCallback();
      return { message: 'Failure', status: 'err' };
    } else {
      data.successCallback && await data.successCallback();
      return { message: 'Success', status: 'ok' };
    }
  } else {
    data.loadingCallback && await data.loadingCallback();
    await fetch(window.location.origin, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        msg: {
          command: 'on',
          click_id: data.clickId,
          part_id: data.partId
        }
      })
    }).then(response => response.json()).then(d => {
      data.successCallback && data.successCallback();
      return { message: 'success', status: 'ok' };
    }).catch(err => {
      data.failureCallback && data.failureCallback();
      return { message: err, status: 'err' };
    });
  }
};

export default fetchSubscribe;
