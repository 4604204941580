//#region imports
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TTheme } from '../../configs/theme';
//#endregion

export default makeStyles<TTheme>((theme: TTheme) => createStyles({
  root: {
    margin: '0 20px 0 20px',
    borderRadius: 15,
    background: `${ theme.palette.info.main }`,
    boxShadow: '0px 6px 4px rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down(355)]: {
      backgroundPosition: 'right -140px center !important'
    }
  },
  container: {
    backgroundColor: theme.name === 'theme1' ? 'transparent' : theme.palette.background.default,
    padding: theme.name === 'theme4' ? '80px 20px 0' : 'initial'
  },
  content: {
    '.theme1 &': {
      padding: '11px 20px 8px',
      textAlign: 'center'
    }
  },
  picture: {
    maxWidth: '100%',
    '.theme1 &': {
      maxHeight: 190
    }
},
  text: {
    display: 'flex',
    '.theme1 &': {
      padding: '10px 0 16px',
      maxWidth: 305,
      margin: '0 auto'
    }
  },
  header: {
    fontFamily: theme.typography.caption.fontFamily,
    color: theme.palette.text.secondary,
    textAlign: 'center',
    '.theme1 &': {
      fontSize: 24,
      lineHeight: '25px',
      padding: '26px 20px 0px'
    }
  },
  desc: {
    fontSize: 13,
    lineHeight: '16.87px',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '-0.3px'
  }
}),
  { name: 'Banner' }
);
