//#region imports
import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
//#endregion

const Loader = () => (
  <Box style={ {
    width: '100vw',
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  } }>
    <CircularProgress size={ 72 } />
  </Box>
);

export default Loader;
