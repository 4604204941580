//#region imports
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TTheme } from '../../configs/theme';
import prizeImg from '../../assets/img/prize.webp';
//#endregion

export default makeStyles<TTheme>((theme: TTheme) => createStyles({
  root: {
    margin: '13px 0 20px'
  },
  container: {
    '.theme1 &': {
      padding: '26px 19px 0'
    }
  },
  wrapper: {
    background: 'none'
  },
  picture: {
    width: 'unset',
    height: '60vw',
    maxHeight: 427,
    margin: '0 auto',
    background: `bottom / contain no-repeat url(${prizeImg})`
  },
  header: {
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    letterSpacing: '-0.625px',
    overflow: 'hidden',
    height: 70,
    padding: '0 22px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.main,
    fontFamily: theme.typography.caption.fontFamily,
    lineHeight: '70px',
    borderRadius: '15px 15px 0 0',
    '.theme1 &': {
      fontSize: 24
    }
  },
  label: {
    marginBottom: 15,
    fontFamily: theme.typography.caption.fontFamily,
    fontSize: 16
  },
  icon: {
    marginRight: 15,
    '&.theme4': {
      marginRight: 10
    }
  },
  item: {
    display: 'flex',
    marginBottom: 11.5,
    fontWeight: 400,
    fontSize: 16,
    fontFamily: theme.typography.body1.fontFamily,
    '& b': {
      fontSize: 21,
      fontFamily: theme.typography,
      fontWeight: 700,
      marginLeft: 5
    },
    '&.kz b': {
      marginRight: 5,
      marginLeft: 0
    }
  },
  superPrizeContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: '24px 0 22px -6px'
  },
  superPrize: {
    '&.theme1': {
      marginLeft: -7,
      height: 50
    }
  },
  text: {
    marginBottom: 10,
    fontSize: 16,
    fontFamily: theme.typography.caption.fontFamily
  },
  amount: {
    fontSize: 28,
    fontFamily: theme.typography.caption.fontFamily,
    color: theme.palette.primary.main,
    '&.theme4': {
      fontSize: 34
    }
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'auto',
    margin: '16px auto 36px auto',
    maxWidth: 278,
    '& button .MuiButton-label': {
      padding: '0 10px'
    }
  }
}),
  { name: 'Prizes' }
);
