import { createTheme } from '@material-ui/core/styles';
import { TTheme } from './index';

export const theme1Colors = {
  white: '#FFFFFF',
  grey: '#C4C4C4',
  yellow: '#FECA0A',
  blue: '#275EAC',
  dark: '#3A3A3A',
  neutral: '#8F8F8F'
};

export const theme1Fonts = {
  calibri: 'Calibri, Roboto, Arial, sans-serif',
  calibriBold: 'CalibriBold, Roboto, Arial, sans-serif',
  inter: 'Inter, Roboto, Arial, sans-serif',
  interBold: 'InterBold, Roboto, Arial, sans-serif'
};

export const theme1 = createTheme({
  name: 'theme1',
  palette: {
    background: {
      paper: theme1Colors.blue,
      default: theme1Colors.yellow
    },
    text: {
      secondary: theme1Colors.dark,
      disabled: theme1Colors.neutral
    },
    common: {
      white: theme1Colors.white
    },
    grey: {
      A100: theme1Colors.grey
    },
    primary: {
      main: theme1Colors.blue,
      contrastText: theme1Colors.blue,
      light: theme1Colors.neutral
    },
    secondary: {
      main: theme1Colors.blue
    },
    info: {
      main: theme1Colors.yellow,
      contrastText: theme1Colors.white
    },
    action: {
      selected: theme1Colors.yellow
    }
  },
  typography: {
    fontFamily: theme1Fonts.calibri,
    body1: {
      fontFamily: theme1Fonts.calibri
    },
    body2: {
      fontFamily: theme1Fonts.inter
    },
    caption: {
      fontFamily: theme1Fonts.calibriBold
    },
    button: {
      fontFamily: theme1Fonts.calibriBold
    }
  },
  overrides: {
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: 'none'
        }
      },
      text: {
        padding: '0'
      }
    }
  }
} as unknown as TTheme);
