//#region imports
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TTheme } from '../../configs/theme';
//#endregion

export default makeStyles<TTheme>((theme: TTheme) => createStyles({
  wrapper: {
    width: 'unset',
    margin: '0 20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  container: {
    flexBasis: '41%',
    display: 'flex',
    alignItems: 'center',
    margin: '0 20px',
    padding: '35px 20px',
    marginTop: 13,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: 15,
    border: `1px solid ${ theme.palette.grey.A100 }`,
    '&.theme2': {
      margin: '65px 0 0 0',
      borderRadius: 0
    },
    '&.theme3': {
      background: theme.palette.background.paper,
      color: theme.palette.common.black,
      '& a': {
        color: theme.palette.common.black
      },
      '&.primary': {
        background: theme.palette.primary.main,
        color: theme.palette.common.white
      }
    }
  },
  icon: {
    marginRight: 20,
    '&.theme2': {
      position: 'absolute',
      maxWidth: 160,
      left: -12,
      bottom: -7
    }
  },
  desc: {
    fontSize: 14,
    lineHeight: '16px',
    letterSpacing: -0.35,
    fontFamily: theme.typography.fontFamily,
    '& a': {
      color: theme.palette.common.white
    }
  }
}),
  { name: 'Card' }
);
